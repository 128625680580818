<template lang="pug">
    .footer ©2023 Gabriel Gnatowski
</template>

<script>
export default {
    
}
</script>

<style lang="less" scoped>
@import (reference) '../shared.less';

.footer {
    flex: 0 0 auto;
    border-top: 1px solid @c4;
    padding: 0.5rem 0;
    font-size:1vw;
}
</style>