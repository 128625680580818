<template lang="pug">
    .body
        .bg
        .info(:style="style1") {{t1}}
        .info(:style="style2") {{t2}}
</template>

<script>

export default {
    data() {
        return {
            skills: [
                'HTML',
                'PUG',
                'CSS',
                'LESS',
                'Javascript',
                'Typescript',
                'NodeJS',
                'Vue',
                'mySQL',
                'Sketchup',
                'POVray',
                'Kerkythea'
            ],
            t1: '',
            t2: '',
            interval: null,
            morphTime: 3,
            cooldownTime: 1,

            textIndex: 0,
            time: 0,
            morph: 0,
            cooldown: 0,
            style1: '',
            style2: ''
        }
    },
    methods: {
        // switch() {
        //     this.t1 = this.t2;
        //     this.t2 = this.t2 < this.skills.length-1 ? this.t2 + 1 : 0;
        // },
        doMorph() {
            this.morph -= this.cooldown;
            this.cooldown = 0;

            let fraction = this.morph / this.morphTime;

            if (fraction > 1) {
                this.cooldown = this.cooldownTime;
                fraction = 1;
            }

            this.setMorph(fraction);
        },
        setMorph(fraction) {
            this.style2 = { filter: `blur(${Math.min(18 / fraction - 18, 100)}px)`, opacity: `${Math.pow(fraction, 0.4) * 100}%`};
            fraction = 1 - fraction;
            this.style1 = { filter: `blur(${Math.min(18 / fraction - 18, 100)}px)`, opacity: `${Math.pow(fraction, 0.4) * 100}%`};

            this.t1 = this.skills[this.textIndex % this.skills.length];
            this.t2 = this.skills[(this.textIndex+1) % this.skills.length];
        },
        doCooldown() {
            this.morph = 0;

            this.style2 = { filter: "", opacity: "100%" };
            this.style1 = { filter: "", opacity: "0%" };
        },
        animate() {
            requestAnimationFrame(this.animate);

            let newTime = new Date();
            let shouldIncrementIndex = this.cooldown > 0;
            let dt = (newTime - this.time) / 1000;
            this.time = newTime;

            this.cooldown -= dt;

            if (this.cooldown <= 0) {
                if (shouldIncrementIndex) {
                    this.textIndex++;
                }

                this.doMorph();
            } else {
                this.doCooldown();
            }
        }
    },
    mounted() {
        // this.interval = setInterval(() => {
        //     this.switch();
        // }, 5000)
        this.textIndex = this.skills.length - 1;
        this.time = new Date();
        this.morph = 0;
        this.cooldown = this.cooldownTime;
        this.t1 = this.skills[this.textIndex % this.skills.length];
        this.t2 = this.skills[(this.textIndex+1) % this.skills.length];
        this.animate();
    }
}
</script>

<style lang="less" scoped>
@import (reference) '../shared.less';
.body {
    flex:1 0;
    overflow:auto;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: center;
    position: relative;
}

.bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-image: url('/img/IMG-20220228-WA0006.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.3;
    
}
.info {
    // font-size: 20rem;
    color: #000;
    position:absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    display:flex;
    flex-flow:column;
    justify-content:center;
    align-items:center;
    font-size: 12vw;
}

@media (max-width:700px) {

}
</style>