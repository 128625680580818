<template lang="pug">
div(style="flex:1 0;display:flex;flex-flow:column;align-items:center;")
  .recipes
    .recipe(
      v-for="r in recipes",
      @click="select(r)"
    ) 
      .image(:style="{ backgroundImage: 'url(/data/recipes/' + r.images[0] + ')' }")
      .name {{r.name}}
</template>

<script>
import { mapGetters } from "vuex";
// import Recipe from "@/components/Recipe.vue";
import Icon from "@/components/Icon.vue";
import Button3d from "@/components/Button3d.vue";
export default {
  components: { Icon, Button3d },
  data() {
    return {
      current: null,
      // recipes: [
      //   {
      //     id: "e774799b-b6c9-4146-a9fe-3c42b2911131",
      //     name: "Lende im Speckmantel aus dem Backofen",
      //     ingredients: [
      //       { n: "Schweinelende", a: 500, u: "g" },
      //       { n: "Senf", a: 1, u: "TL" },
      //       { n: "Bacon", a: 120, u: "g" },
      //       { n: "Rosmarinzweige", a: 2 },
      //       { n: "Honig", a: 1, u: "EL" },
      //       { n: "Salz & Pfeffer" },
      //       { n: "Öl zum Braten" },
      //     ],
      //     steps: [
      //       {
      //         h: "Schritt 1 - Schweinefilet panieren und anbraten",
      //         t: "Ofen auf 180°C Umluft vorheizen. Das Schweinefilet von den Sehnen befreien, kräftig mit Salz und Pfeffer würzen und in einer Pfanne mit wenig Öl von allen Seiten anbraten.",
      //       },
      //       {
      //         h: "Schritt 2 - Schweinefilet marinieren und in Speck einwickeln",
      //         t: "Den Bacon leicht überlappend auf ein Brett legen. Den Rosmarin darüber zupfen, die Lende darauf legen und von allen Seiten mit Senf bestreichen. Anschließend fest mit dem Bacon umhüllen.",
      //       },
      //       {
      //         h: "Schritt 3 - Lende im Speckmantel mit Honig einstreichen",
      //         t: "Danach die Lende im Speckmantel auf ein Backblech setzen und alles mit Honig einstreichen.",
      //       },
      //       {
      //         h: "Schritt 4 - Lende im Speckmantel im Backofen garen",
      //         t: "Im Ofen für ca. 20 Minuten garen lassen. Wem der Bacon nicht knusprig genug ist, kann noch einmal für wenige Minuten auf Grill schalten.",
      //       },
      //       {
      //         h: "Schritt 5 - Schweinefilet im Speckmantel kurz ruhen lassen und servieren",
      //         t: "Die Lende herausnehmen und für 5 Minuten ruhen lassen, damit sich der Fleischsaft setzt und nicht so viel Flüssigkeit austritt.",
      //       },
      //     ],
      //   },
      //   {
      //     id: "03a70de1-03e3-452a-8268-cb04e8b80f5f",
      //     name: "Gyrosschichtbraten",
      //     ingredients: [
      //       { a: 500, u: "g", n: "Rotkohl, fein geschnitten" },
      //       { a: 170, u: "g", n: "Gyrosgewürz" },
      //       { a: 2.5, u: "kg", n: "Schweinenacken" },
      //       { a: 700, u: "g", n: "Zwiebeln, geschält und fein geschnitten" },
      //       { a: 120, u: "ml", n: "Olivenöl" },
      //       { n: "wenig Salz und Pfeffer" },
      //     ],
      //     steps: [
      //       {
      //         t: "1. Als erstes den Schweinehals vom Metzger der Länge nach so wie Rouladen schneiden lassen und wieder aufeinander legen lassen.",
      //       },
      //       {
      //         t: "2. Jetzt Gyrosgewürz in einer Schale mit dem Öl vermengen, mit Salz und Pfeffer abschmecken, so dass es eine schöne flüssige Marinade ergibt. Die erste Scheibe Schweinehals hinlegen und nur die sichtbare Seite mit der Marinade bepinseln. Eine Schicht Zwiebeln draufgeben (und nach Belieben etwas Rotkohl) und so weiter schichten bis alles aufgebraucht ist.",
      //       },
      //       {
      //         t: "3. Nun zum Binden erst am Anfang vom Braten einen Knoten, dann am Schluss und dann in der Mitte. Die ersten 3 Bindungen nicht so fest, da sonst die Füllung rausgedrückt wird. Danach wie gewohnt binden, immer mit Gefühl.",
      //       },
      //       {
      //         t: "4. Bei 190°C Ober-/Unterhitze 90 bis 120 Minuten bis zu einer Kerntemperatur von 75 °C im vorgeheizten Offen braten. (Immer wieder mit dem Bratensaft begießen). Nicht erschrecken, der Braten geht stark ein, weil die Zwiebeln auch Wasser verlieren.Beim Servieren sich von Bindfaden zu Bindfaden vorarbeiten, das Fleisch ist gleich klein geschnitten.",
      //       },
      //     ],
      //     images: [
      //       "414552531_906756961237330_850760985496818835_n.jpg",
      //       "Gyrosschichtbraten-site.jpg",
      //     ],
      //   },
      // ],
    };
  },
  computed: {
    ...mapGetters({ recipes: "recipes/items" })
  },
  methods: {
    select(r) {
      this.$router.push({ name: 'recipe', params: { id: r.id } }).catch(err => { console.log('error', err )});
    },
  },
};
</script>

<style lang="less" scoped>
.recipes {
  flex: 1 0;
  display: flex;
  align-items: stretch;
  flex-flow: column wrap;
  margin: 0 1rem;
  justify-content: flex-start;
  align-items: stretch;
  color: black;
  cursor: pointer;
  font-size: 2rem;
  margin:0 2rem;
}
.recipe {
  display:flex;
  flex: 0 0 auto;
  margin: 0.5rem;
  padding:0.5rem 1rem;
  border:1px solid gray;
  border-radius: 0.5rem;
  background-color: #eee;
  box-shadow: #777 0.25rem 0.25rem 0.25rem;
  align-items: center;
}
.image {
  width: 160px;
  height: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  margin:0 1rem 0 0;
}

.name {
  flex:0 0 auto;
}
</style>