<template lang="pug">
  .content
    .bg 
    .text
      ul
        li Abgeschlossenes Studium zum EDV-Kaufmann
        li Über 40 Jahre Berufserfahrung
        li Entwicklung in Javascript/Typescript
        li Webdesign mit HTML/PUG und CSS/LESS
        li Datenbankdesign für mySQL
        li Webentwicklung mit VueJS
      p 2 Jahre als Supporter an einem Großrechner in Danzig in einem Rechenzentrum für Bauindustrie
      p 14 Jahre als Entwickler in einem Rechenzentrum für Schuheinzelhandel in Dortmund 
      p 25 Jahre Mitgesellschafter und Entwickler in einem Unternehmen, welches Software für Schuhhandel und Schuhindustrie entwickelt hat
      p Seit November 2022 selbstständiger Entwickler in Grömitz an der Ostsee
      p Seit Juli 2024 selbständiger Entwickler in Emmerich am Rhein
</template>

<script>
export default {
  metaInfo: {
    title: 'Einige Informationen über mich'
  }
}
</script>
<style lang="less" scoped>
.content {
  position: relative;
  flex: 1 0;
  display: flex;
  // flex-flow: column;
  align-items: flex-end;
  padding: 1rem;
  color: #000;
  justify-content: flex-end;
}
.bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-image: url('/img/20220830_115710.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 27%;
    opacity: 0.2;
    animation: 2s back forwards;
}
.text {
  flex:1 0;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  margin: 1rem;
  text-align: left;
  animation: 1s text forwards;
  overflow: auto;
  position: relative;
}
@keyframes text {
  from { transform: translateY(600px); }
  to { transform: translateY(0); }
}
@keyframes back {
  from { filter: blur(100px); opacity: 1; }
  to { filter: blur(0); opacity: 0.2; }
}

li {
  text-align: left;
  font-size: 1.5rem;
}
p {
  margin-block: 0.3rem;
  font-size: 1.5rem;
}

@media screen and (max-device-height: 500px) and (orientation: landscape) {
  .content {
    padding: 0;
  }
  .text {
    margin: 0.5rem;
  }
  li {
    text-align: left;
    font-size: 1rem;
  }
  p {
    margin-block: 0.1rem;
    font-size: 0.9rem;
  }
}
</style>