<template lang="pug">
    .content
        h2 Kontakt
        div(style="flex:1 0;display:flex;flex-flow:column;")
            .left Gabriel Gnatowski
            .right Brenkenhagener Weg 39
            .left 23743 Grömitz

            .right E-Mail: gnatowski@web.de
            .left Tel.: +49 1522 1648102

</template>

<script>

export default {
    metaInfo: {
        title: 'Meine Kontaktdaten'
    }
}
</script>

<style lang="less" scoped>
.content {
    flex:1 0;
    display: flex;
    flex-flow: column nowrap;
    color: black;
    font-size: 1.8rem;
    overflow: auto;
}
.content div {
    margin: 1rem 0;
}
.left {
    animation: 1s left forwards;
}
.right {
    animation: 1s right forwards;
}

@keyframes left {
    from { transform: translateX(-1200px) rotateZ(90deg) scale(3); }
    to { transform: translateX(0) rotateZ(0) scale(1); }
}
@keyframes right {
    from { transform: translateX(1200px) rotateZ(-90deg) scale(3); }
    to { transform: translateX(0) rotateZ(0) scale(1); }
}
@media screen and (max-device-height: 500px) and (orientation: landscape) {
    h2 { flex: 1 0; }
    .content {
        font-size: 3vw;
        flex-flow: row;
        align-items:center;
    }
    .content div {
        margin: 0.2rem 0;
    }
}
</style>