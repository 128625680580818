"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const axios_1 = require("axios");
const a = axios_1.default.create({
    // baseURL: `http://192.168.178.20:9003/gg`
    baseURL: `https://gnatowski.ddns.net`
});
const s = axios_1.default.create({
    baseURL: `http://192.168.178.55:8087`
});
exports.default = {
    // user: {
    //     login: (username, password) => a.post('/api/login', { username, password })
    // },
    tools: {
        kfz: () => a.get(`/api/kfz`),
        smoked: () => a.get(`/api/smoked/today`),
        smokedOn: d => a.get(`/api/smoked/${d}`),
        smoke: person => a.post(`/api/smoke`, { person })
    },
    stairs: {
        effects: () => s.get('/api/effects'),
        clear: () => s.post('/api/clear'),
        lower: (id) => s.get(`/api/distance/lower`),
        upper: (id) => s.get(`/api/distance/upper`),
        effect: (effect) => s.post(`/api/effect/${effect}`),
        color: (c) => { const t = `/api/color/${c.replace('#', '')}`; s.post(t); console.log('api.color', c, t); },
        play: (dir) => s.post(`/api/play/${dir}`),
        copy: (data) => s.post(`/api/copy`, data),
    },
    media: {
        files: (filter, page) => a.get(`/api/media/files`, { params: { page, ...(filter || {}) } }),
        item: (id) => a.get(`/api/media/item/${id}`),
        content: (id) => a.get(`/api/media/content/${id}`, { responseType: 'arraybuffer' })
    },
};
